$itaa-blue: #142F4B;
$itaa-gold: #d49c5f;
$itaa-lightblue: #6ec1e4;
$itaa-greyblue: #9cd3e2;

$white: #ffffff;
$grey-1: #f4f4f2;
$grey-2: #dbdbdb;
$grey-3: #c4c4c4;
$grey-4: #b0b0b0;
$grey-5: #a1a1a1;
$grey-6: #7a7a7a;
$grey-7: #5b5b5b;
$grey-8: #333333;
$grey-9: #272727;
$black: #000000;

$itaa-red: #b00020;

$itaa-teal: #2292A4;

$itaa-purple: #9888A5;

$itaa-green: #84C318;

$itaa-green-dark: #5b8a09;

$itaa-warning: #FF9800;
$primary-400: #507293;